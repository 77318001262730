;(function ($) {
    $(document).ready(function () {
        const $header = $("header");
        const $menuIcon = $header.find('.js-toggle-menu');
        const $menuList = $header.find('.js-menu-list');
        const $closedOfferedTop = $header.find(".js-offered_top_line .js-close-icon");
        const $OfferedTop = $header.find(".js-offered_top_line");
        $closedOfferedTop.on('click', function () {
            $header.addClass("no-top-line");
            $OfferedTop.hide();
        });
        $menuIcon.on('click', function () {
            $(this).toggleClass('active');
            $menuList.slideToggle();
            $menuList.toggleClass('active');
        });
        $menuList.on('click', ".menu-item-has-children", function (e) {
            e.stopImmediatePropagation();
            const $this = $(this),
                $currentSub = $this.find(".sub-menu").first(),
                $nearestSub = $this.closest(".sub-menu"),
                $nearestToggledSub = $nearestSub.find(".menu-toggle"),
                $hasToggledSub = $this.closest(".js-menu-list").find(".has-menu-toggle"),
                $allSub = $hasToggledSub.find(".menu-toggle");
            if ($this.hasClass("has-menu-toggle")) {
                $currentSub.slideUp();
                $currentSub.removeClass("menu-toggle");
                $this.removeClass("has-menu-toggle");
                return;
            }
            if ($nearestSub.length <= 0) {
                $allSub.slideUp();
                $allSub.removeClass("menu-toggle");
                $hasToggledSub.removeClass("has-menu-toggle");
            } else {
                $nearestToggledSub.slideUp();
                $nearestToggledSub.removeClass("menu-toggle");
                $nearestToggledSub.parent().removeClass("has-menu-toggle");
            }
            $currentSub.slideDown();
            $currentSub.addClass("menu-toggle");
            $this.addClass("has-menu-toggle");
        })
    })
}(jQuery))
