document.addEventListener('DOMContentLoaded', start)

function start() {
    const postsWrapper = document.querySelectorAll('.resources__wrap ')
    if (postsWrapper && postsWrapper.length) {
        Array.from(postsWrapper).forEach(sliderInit)
    }
}

/**
 *
 * @param wrapper {HTMLElement}
 */
async function sliderInit(wrapper) {
    try {
        const {Swiper, Scrollbar, Navigation} = await import('swiper')
        Swiper.use([Scrollbar, Navigation])
        new Swiper(wrapper, {
            scrollbar: {
                el: ".swiper-scrollbar",
                draggable: true
            },
            slidesPerView: 1,
            spaceBetween: 30,
            breakpoints: {
                640: {
                    slidesPerView: 2,
                },
                // when window width is >= 480px
                1200: {
                    slidesPerView: 3,
                },
            },
            navigation:{
                prevEl: '.resources__arrow--prev',
                nextEl: '.resources__arrow--next',
                disabledClass: 'resources__arrow--disabled'
            }
        })
    } catch (e) {

    }
}
