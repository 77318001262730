document.addEventListener('DOMContentLoaded', start)

function start() {
    const allPostWrapper = document.querySelectorAll('.js-news-grid-wrap')
    if (!allPostWrapper || !allPostWrapper.length) return
    Array.from(allPostWrapper).forEach(wrapper => wrapper.addEventListener('click', init))
}

/**
 *
 * @param event {PointerEvent}
 */
async function init(event) {
    const wrapper = event.currentTarget
    const btn = event.target.closest('.js-button-loader')
    if (!btn) return

    const grid = wrapper.querySelector('.js-news-grid')
    const btnText = btn.innerText

    const newsPerPage = btn.dataset.perPage
    const newsOffset = btn.dataset.offset
    const postType = btn.dataset.postType
    const foundPosts = parseInt(btn.dataset.foundPosts)
    const body = new URLSearchParams()

    body.append('offset', newsOffset)
    body.append('perPage', newsPerPage)
    body.append('action', 'load_more_news')
    body.append('nonce', load_more_news_props.nonce)
    body.append('post_type', postType)


    btn.innerText = '...Loading'
    grid.classList.add('active')
    btn.disabled = 1
    try {
        const response = await fetch(load_more_news_props.url, {
            method: 'POST',
            body
        })

        if (!response.ok || response.status !== 200) throw new Error(response.statusText)
        const json = await response.json()

        if (json.hasOwnProperty('newsHtml')) grid.innerHTML += json.newsHtml

        if (json.hasOwnProperty('news-offset')) btn.setAttribute('data-offset', json['news-offset'])
    } catch (e) {
        console.log(e)
    } finally {
        grid.classList.remove('active')
        btn.disabled = 0
        btn.innerText = btnText
        const offset = parseInt(btn.dataset.offset)
        if (offset >= foundPosts) {
            btn.style.display = 'none'
        }
    }
}