document.addEventListener('DOMContentLoaded', start)

function start() {
    const popupBtn = document.querySelectorAll('[data-video-popup]')
    if (!popupBtn || !popupBtn.length) return

    Array.from(popupBtn).forEach(init)
}

/**
 *
 * @param element {HTMLElement}
 * @returns {Promise<void>}
 */
async function init(element) {
    try {
        const {Fancybox} = await import('@fancyapps/ui')
        Fancybox.bind('[data-video-popup]', {
            caption: element.dataset.caption ?? '' ,
            groupAll: element.dataset.videoPopup === 'gallery',
            tpl: {
                closeButton: `<button data-fancybox-close class="f-button is-close-btn 123" title="{{CLOSE}}"><?xml version="1.0" encoding="UTF-8"?>
                    <svg width="33px" height="32px" viewBox="0 0 33 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Close button</title>
        <g id="ALSO-Website" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ALSO_Beach_House_w_Overlay" transform="translate(-1273.000000, -97.000000)" stroke="#A2ADB3"
               stroke-width="2">
                <g id="Close-button" transform="translate(1273.005593, 97.000000)">
                    <rect id="Rectangle" x="1" y="1" width="30" height="30" rx="15"></rect>
                    <g id="Group-4"
                       transform="translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) translate(10.000000, 10.000000)"
                       stroke-linecap="round">
                                    <line x1="5.875" y1="0" x2="5.875" y2="12" id="Path"></line>
                                    <line x1="6" y1="-5.06806957e-15" x2="6" y2="12" id="Path"
                                        transform="translate(6.000000, 6.000000) rotate(270.000000) translate(-6.000000, -6.000000) "></line>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>`
            }
        })
    } catch (e) {

    }
}